@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-bg-primary-color text-white font-roboto;
  }

  ::-webkit-scrollbar {
    width: 0.8rem;
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, var(--bg-secondary) 25%, var(--primary));
    border-radius: 0.4rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(transparent, var(--bg-secondary) 10%, var(--primary));
    border-radius: 0.4rem;
  }

  ::-webkit-scrollbar-track {
    background: var(--bg-primary);
  }
}
